import React, { useState, useCallback, useMemo } from 'react';
import { IconsType } from 'components/Icon';
import Dialog from 'components/Dialog';
import useStyles from './fundedInvestingManualKycDialog.styles';
import { useTranslation } from 'react-i18next';
import { FundedInvestingManualKycDialogProps } from './fundedInvestingManualKycDialog.scheme';
import { UploadedFileWithType } from '../FundedSelect';
import VerifyIdentityStep from './FundedInvestingComponents/verifyIdentityStep.render';
import FundedStep from './FundedInvestingComponents/fundedStep.render';
import TermsStep from './FundedInvestingComponents/termsStep.render';
import VerificationInProgressStep from './FundedInvestingComponents/verificationInProgressStep.render';
import YouAreVerifiedStep from './FundedInvestingComponents/youAreVerifiedStep.render';
import DocumentResubmissionStep from './FundedInvestingComponents/documentResubmissionStep.render';
import VerificationAssistanceRequiredStep from './FundedInvestingComponents/verificationAssistanceRequiredStep.render';
import {
    AccountInfoParsedKycVerificationStatusEnum,
    ManualKycRequestKycStatusEnum,
    UploadFileWithDocumentTypeRequest,
    UploadFileWithDocumentTypeRequestDocumentTypeEnum,
} from 'services/generatedClientFromSwagger';
import config from 'config/common';
import IconBox from 'components/IconBox/iconBox.render';
import { IconColor } from 'components/IconBox/iconBox.scheme';

const FundedInvestingManualKycDialog: React.FunctionComponent<FundedInvestingManualKycDialogProps> = ({
    isOpen,
    onClose,
    onAccept,
    userInfo,
    challengeId,
    updateStatusManualKyc,
    manualKycLoading,
    acceptChallengeTermsAndCondition,
}) => {
    const [isTermsCheckboxChecked, setIsTermsCheckboxChecked] = useState(false);
    const [selectedFileType, setSelectedFileType] = useState<
        UploadFileWithDocumentTypeRequestDocumentTypeEnum | undefined
    >(UploadFileWithDocumentTypeRequestDocumentTypeEnum.NationalIdCard);
    const [files, setFiles] = useState<UploadedFileWithType[]>([]);
    const { t } = useTranslation('prop');
    const classes = useStyles();

    const handleTermsCheckboxChange = useCallback(() => {
        setIsTermsCheckboxChecked((prevState) => !prevState);
    }, []);

    const currentStep = useMemo(
        () => (config.featuresFlags['manualKyc'] ? userInfo.kycVerificationStatus : undefined),
        [userInfo.kycVerificationStatus]
    );

    const handleNextStep = useCallback(
        (optionalStatus?: AccountInfoParsedKycVerificationStatusEnum) => {
            const status = optionalStatus || currentStep;
            if (!status) return;

            switch (status) {
                case AccountInfoParsedKycVerificationStatusEnum.NotStarted:
                    updateStatusManualKyc({ kycStatus: ManualKycRequestKycStatusEnum.NotStarted });
                    break;
                case AccountInfoParsedKycVerificationStatusEnum.Approved:
                    updateStatusManualKyc({
                        challengeId,
                        kycStatus: ManualKycRequestKycStatusEnum.TraderAgreementAccepted,
                    });
                    onAccept();
                    break;
                case AccountInfoParsedKycVerificationStatusEnum.Resubmission:
                case AccountInfoParsedKycVerificationStatusEnum.DocumentUploaded:
                    const uploadedFiles: UploadFileWithDocumentTypeRequest[] = files.map(({ name, base64 }) => ({
                        fileName: name,
                        fileContent: base64?.split(',')[1],
                        documentType: selectedFileType,
                        accountId: userInfo.id,
                    }));
                    updateStatusManualKyc({
                        kycStatus: ManualKycRequestKycStatusEnum.DocumentUploaded,
                        files: uploadedFiles,
                    });
                    break;
                case AccountInfoParsedKycVerificationStatusEnum.InProgress:
                case AccountInfoParsedKycVerificationStatusEnum.Rejected:
                    onAccept();
                    onClose();
                    break;
                default:
                    break;
            }
        },
        [challengeId, currentStep, files, onAccept, onClose, updateStatusManualKyc, userInfo.id]
    );

    const renderIconBox = useCallback(
        (color: IconColor, iconType: IconsType) => (
            <div className={classes.iconsContainer}>
                <IconBox variant="border" color={color} size={48} iconType={iconType} />
            </div>
        ),
        [classes.iconsContainer]
    );

    const renderStep = useCallback(() => {
        switch (currentStep) {
            case AccountInfoParsedKycVerificationStatusEnum.NotStarted:
                return (
                    <>
                        {renderIconBox('success', IconsType.coinsStacked2)}
                        <VerifyIdentityStep
                            // selectedFileType={selectedFileType}
                            // setSelectedFileType={setSelectedFileType}
                            files={files}
                            setFiles={setFiles}
                            t={t}
                            uploadD_processLoading={false}
                            handleNextStep={handleNextStep}
                            disabled={manualKycLoading}
                        />
                    </>
                );
            case AccountInfoParsedKycVerificationStatusEnum.DocumentUploaded:
            case AccountInfoParsedKycVerificationStatusEnum.InProgress:
                return (
                    <>
                        {renderIconBox('success', IconsType.shield_plus)}
                        <VerificationInProgressStep onClose={onClose} />
                    </>
                );
            case AccountInfoParsedKycVerificationStatusEnum.Approved:
                return (
                    <>
                        {renderIconBox('success', IconsType.shield_tick)}
                        <YouAreVerifiedStep
                            handleCheckboxChange={handleTermsCheckboxChange}
                            isCheckboxChecked={isTermsCheckboxChecked}
                            handleNextStep={handleNextStep}
                            disabled={manualKycLoading}
                        />
                    </>
                );
            case AccountInfoParsedKycVerificationStatusEnum.Resubmission:
                return (
                    <DocumentResubmissionStep
                        renderChildren={() => (
                            <>
                                {renderIconBox('success', IconsType.shield_plus)}
                                <VerifyIdentityStep
                                    // selectedFileType={selectedFileType}
                                    // setSelectedFileType={setSelectedFileType}
                                    files={files}
                                    setFiles={setFiles}
                                    t={t}
                                    uploadD_processLoading={false}
                                    handleNextStep={handleNextStep}
                                    disabled={manualKycLoading}
                                />
                            </>
                        )}
                        handleNextStep={handleNextStep}
                    />
                );
            case AccountInfoParsedKycVerificationStatusEnum.Rejected:
                return (
                    <>
                        {renderIconBox('gray', IconsType.shield_plus)}
                        <VerificationAssistanceRequiredStep handleNextStep={onClose} />
                    </>
                );
            default:
                return config.featuresFlags['manualKyc'] && !userInfo.kycVerificationStatus ? (
                    <>
                        {renderIconBox('success', IconsType.coinsStacked2)}
                        <FundedStep
                            handleNextStep={() => handleNextStep(AccountInfoParsedKycVerificationStatusEnum.NotStarted)}
                            loading={manualKycLoading}
                        />
                    </>
                ) : (
                    <>
                        {renderIconBox('success', IconsType.shield_plus)}
                        <TermsStep
                            isCheckboxChecked={isTermsCheckboxChecked}
                            handleCheckboxChange={handleTermsCheckboxChange}
                            onSubmit={onAccept}
                        />
                    </>
                );
        }
    }, [
        currentStep,
        renderIconBox,
        files,
        t,
        handleNextStep,
        manualKycLoading,
        onClose,
        handleTermsCheckboxChange,
        isTermsCheckboxChecked,
        userInfo.kycVerificationStatus,
        onAccept,
    ]);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            classes={{ paper: classes.dialogPaper, root: classes.backDrop }}
            closeButtonIcon={{ icon: IconsType.x_close }}
        >
            {renderStep()}
        </Dialog>
    );
};

export default FundedInvestingManualKycDialog;
