import * as React from 'react';
import { ChallengeHeaderSelectProps, mapDispatchToProps, mapStateToProps } from './challengeHeaderSelect.scheme';
import useStyles from './challengeHeaderSelect.styles';
import { useTranslation } from 'react-i18next';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';
import { Pages } from 'interfaces/main';
import { connect } from 'react-redux';
import { Drawer, Hidden, MenuItem, Select } from '@material-ui/core';
import Icon, { IconsType } from 'components/Icon';
import classNames from 'classnames';
import { usePropStatusHook } from 'features/prop/hooks/prop-status.hook';
import Button from 'components/Button';
import Stack from 'components/Stack';
import { useLocation } from 'react-router-dom';

const ChallengeHeaderSelect: React.FunctionComponent<ChallengeHeaderSelectProps> = (props) => {
    const { challenges, setSelectedChallenge, selectedChallenge: challenge, shrinkVariant, onSelectHandler } = props;
    const classes = useStyles();
    const { t } = useTranslation('prop');
    const ref = React.useRef(null);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [isSelectOpen, setIsSelectOpen] = React.useState(false);
    const location = useLocation();
    const isPlatformPage = location.pathname.includes(`/${Pages.PLATFORMS}`);

    const selectedChallenge = React.useMemo(() => {
        if (challenges?.length) {
            const exists = challenges?.find((challenge) => challenge.tpName === challenge?.tpName);

            if (exists?.tpName) {
                return challenge;
            }

            if (challenges[0].tpName) {
                return challenges[0];
            }
        }
        return null;
    }, [challenges, challenge]);

    const { statusClass, statusTerm, statusTermCallback, statusClassCallback } = usePropStatusHook({
        challengeStatus: selectedChallenge?.challengeStatus,
    });

    const onChangeHandler = React.useCallback(
        (selectedTpName) => {
            setSelectedChallenge(selectedTpName);
            setIsSelectOpen(false);
        },
        [setSelectedChallenge, setIsSelectOpen]
    );

    const disableScroll = React.useCallback(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    const enableScroll = React.useCallback(() => {
        document.body.style.overflow = 'unset';
    }, []);

    React.useMemo(() => {
        if (isSelectOpen) {
            disableScroll();
        } else {
            enableScroll();
        }

        return () => {
            enableScroll();
        };
    }, [disableScroll, enableScroll, isSelectOpen]);

    const { packageName, tpName, challengeType } = selectedChallenge ?? {};

    return (
        <div className={classNames(classes.select_wrapper, classes.mainSelectFrame)} ref={ref}>
            {challenges?.length > 0 && (
                <>
                    <Hidden xsDown>
                        <Select
                            open={isSelectOpen}
                            onOpen={() => setIsSelectOpen(true)}
                            onClose={() => setIsSelectOpen(false)}
                            key={selectedChallenge?.id}
                            disableUnderline
                            onChange={(event) => onChangeHandler(event?.target.value)}
                            id="challenge_select"
                            IconComponent={(props) => (
                                <Icon
                                    name={IconsType.chevronDownNew}
                                    {...props}
                                    className={`${props.className} ${classes.chevronDownIcon}`}
                                />
                            )}
                            renderValue={(value) => {
                                if (!selectedChallenge) {
                                    return;
                                }
                                const { packageName, tpName } = selectedChallenge;

                                if (shrinkVariant) {
                                    return (
                                        <>
                                            <Stack
                                                className={classes.shrinkItemContainer}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={6}
                                            >
                                                <Stack
                                                    className={classes.tpNameSection}
                                                    direction="column"
                                                    alignItems="center"
                                                >
                                                    <div className={classes.wrapperNameStatus}>
                                                        <Typography
                                                            variant="tLgSemibold"
                                                            color="textSecondary"
                                                            className={classes.packageNameShrink}
                                                        >
                                                            <span data-testid={'packageName'}>{packageName}</span>
                                                        </Typography>
                                                        <Typography
                                                            variant="tXsMedium"
                                                            className={`${classes.status} ${statusClass}`}
                                                        >
                                                            <span data-testid={'statusTerm'}>{statusTerm}</span>
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="tXsRegular" className={classes.menuIdSelect}>
                                                        <div className={classes.id}>{t('selectChallenge.id')}</div>
                                                        <div data-testid={'tpName'}>{tpName}</div>
                                                    </Typography>
                                                </Stack>
                                                <Stack spacing={1} justifyContent="flex-end">
                                                    <Typography variant="tXsMedium" className={classes.chip}>
                                                        <span data-testid={'challengeType'}>
                                                            {selectedChallenge.challengeType}
                                                        </span>
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </>
                                    );
                                }

                                return (
                                    <div className={classes.menuItemContainer}>
                                        <div className={classes.menuItemContent}>
                                            <div className={classes.wrapperNameStatus}>
                                                <Typography variant="tLgSemibold" className={classes.packageName}>
                                                    {packageName}
                                                </Typography>
                                                <Typography
                                                    variant="tXsMedium"
                                                    className={`${classes.status} ${statusClass}`}
                                                >
                                                    <span data-testid={'statusTerm'}>{statusTerm}</span>
                                                </Typography>
                                            </div>

                                            <div className={classes.idStatusContainer}>
                                                <Typography variant="tXsRegular" className={classes.menuIdSelect}>
                                                    <div className={classes.id}>{t('selectChallenge.id')}</div>
                                                    <div data-testid={'tpName'}>{tpName}</div>
                                                </Typography>
                                            </div>
                                        </div>
                                        <Link
                                            to={`/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`}
                                            className={classes.link_statistics}
                                            onMouseDown={(event) => event.stopPropagation()}
                                            data-testid="link-to-my-challenges"
                                        >
                                            <Typography
                                                variant="tXsRegular"
                                                className={classes.link}
                                                component={'span'}
                                            >
                                                {t('selectChallenge.viewStatistics')}
                                            </Typography>
                                        </Link>
                                    </div>
                                );
                            }}
                            value={selectedChallenge?.tpName || ''}
                            MenuProps={{
                                container: ref.current,
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                classes: {
                                    paper: classes.menuSelectPaperRoot,
                                },
                                PaperProps: {
                                    classes: { root: classes.menuSelectPaperRoot },
                                },
                            }}
                        >
                            {challenges?.map((challenge) => (
                                <MenuItem
                                    key={challenge.tpName}
                                    value={challenge.tpName}
                                    className={classes.menuItemSelect}
                                >
                                    <Stack
                                        justifyContent="space-between"
                                        direction="row"
                                        alignItems="center"
                                        spacing={6}
                                        fullWidth
                                    >
                                        <Stack className={classes.tpNameSection} direction="column" alignItems="center">
                                            <div className={classes.wrapperNameStatus}>
                                                <Typography
                                                    variant="tLgSemibold"
                                                    color="textSecondary"
                                                    className={classes.packageNameShrink}
                                                >
                                                    <span data-testid={'packageName'}>{challenge.packageName}</span>
                                                </Typography>

                                                <Typography
                                                    variant="tXsMedium"
                                                    className={`${classes.status} ${statusClassCallback({
                                                        challengeStatus: challenge.challengeStatus,
                                                    })}`}
                                                >
                                                    <span data-testid={'statusTerm'}>
                                                        {statusTermCallback({
                                                            challengeStatus: challenge.challengeStatus,
                                                        })}
                                                    </span>
                                                </Typography>
                                            </div>
                                            <Typography variant="tXsRegular" className={classes.menuIdSelect}>
                                                <div className={classes.id}>{t('selectChallenge.id')}</div>
                                                <div data-testid={'tpName'}>{challenge.tpName}</div>
                                            </Typography>
                                        </Stack>
                                        <Stack justifyContent="flex-end" fullWidth alignItems="center" direction="row">
                                            <Typography variant="tXsMedium" className={classes.chip}>
                                                <span data-testid={'challengeType'}>{challenge.challengeType}</span>
                                            </Typography>
                                            <div className={classes.vIconPlaceholder}>
                                                {challenge.tpName === selectedChallenge?.tpName && (
                                                    <Icon name={IconsType.CheckSvg} />
                                                )}
                                            </div>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    </Hidden>

                    <Hidden smUp>
                        <Button onClick={() => setIsDrawerOpen(true)} className={classes.mobileBtn}>
                            <div className={classes.menuItemContainer}>
                                <div
                                    className={` ${classes.menuItemContent} ${
                                        isPlatformPage ? classes.menuItemContent_platform : ''
                                    }`}
                                >
                                    <Stack
                                        fullWidth={true}
                                        className={classes.shrinkItemContainer}
                                        justifyContent="space-between"
                                        direction="row"
                                        alignItems="center"
                                        spacing={4}
                                    >
                                        <Stack className={classes.tpNameSection} direction="column" alignItems="center">
                                            <div className={classes.wrapperNameStatus}>
                                                <Typography
                                                    variant="tLgSemibold"
                                                    color="textSecondary"
                                                    className={classes.packageNameShrink}
                                                >
                                                    <span data-testid={'packageName'}>{packageName}</span>
                                                </Typography>
                                                <Typography
                                                    variant="tXsMedium"
                                                    className={`${classes.status} ${statusClass}`}
                                                >
                                                    <span data-testid={'statusTerm'}>{statusTerm}</span>
                                                </Typography>
                                            </div>

                                            <Typography variant="tXsRegular" className={classes.menuIdSelect}>
                                                <div className={classes.id}>{t('selectChallenge.id')}</div>
                                                <div data-testid={'tpName'}>{tpName}</div>
                                            </Typography>
                                        </Stack>

                                        <Stack spacing={1} justifyContent="flex-end">
                                            <Typography variant="tXsMedium" className={classes.chip}>
                                                <span data-testid={'challengeType'}>{challengeType}</span>
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <div>
                                        <Icon
                                            name={IconsType.chevronDownNew}
                                            {...props}
                                            className={`${
                                                !isPlatformPage
                                                    ? `${classes.chevronDownIcon} ${
                                                          isDrawerOpen ? classes.chevronRotate : ''
                                                      }`
                                                    : `${classes.chevronDownIcon2} ${
                                                          isDrawerOpen ? classes.chevronRotate2 : ''
                                                      }`
                                            }`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Button>
                        <Drawer
                            anchor="bottom"
                            open={isDrawerOpen}
                            onClose={() => setIsDrawerOpen(false)}
                            PaperProps={{
                                className: classes.drawerPaper,
                            }}
                            data-testid="close-drawer-main"
                        >
                            <Icon
                                name={IconsType.close_drawer}
                                className={classes.icon}
                                onClick={() => setIsDrawerOpen(false)}
                                data-testid="close-drawer"
                            />
                            <div className={classes.drawerContent}>
                                <div className={classes.drawerContentWrapper}>
                                    <Typography variant="tLgBold" className={classes.select_drawer}>
                                        {t('selectChallenge.selectChallenge')}
                                    </Typography>
                                </div>
                                <div className={classes.drawerListContainer}>
                                    {challenges?.map((challenge) => (
                                        <MenuItem
                                            key={challenge.tpName}
                                            value={challenge.tpName}
                                            className={classes.menuItemSelect}
                                            onClick={() => {
                                                onChangeHandler(challenge?.tpName ?? '');
                                                typeof onSelectHandler === 'function' &&
                                                    onSelectHandler(challenge?.tpName ?? '');
                                                setIsDrawerOpen(false);
                                            }}
                                            selected={challenge.tpName === selectedChallenge?.tpName}
                                        >
                                            <Stack
                                                fullWidth={true}
                                                className={classes.shrinkItemContainer}
                                                justifyContent="space-between"
                                                direction="row"
                                                alignItems="center"
                                                spacing={4}
                                            >
                                                <Stack
                                                    className={classes.tpNameSection}
                                                    direction="column"
                                                    alignItems="center"
                                                >
                                                    <div className={classes.wrapperNameStatus}>
                                                        <Typography
                                                            variant="tLgSemibold"
                                                            color="textSecondary"
                                                            className={classes.packageNameShrink}
                                                        >
                                                            <span data-testid={'packageName'}>
                                                                {challenge.packageName}
                                                            </span>
                                                        </Typography>
                                                        <Typography
                                                            variant="tXsMedium"
                                                            className={`${classes.status} ${statusClassCallback({
                                                                challengeStatus: challenge.challengeStatus,
                                                            })}`}
                                                        >
                                                            <span data-testid={'statusTerm'}>
                                                                {statusTermCallback({
                                                                    challengeStatus: challenge.challengeStatus,
                                                                })}
                                                            </span>
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="tXsRegular" className={classes.menuIdSelect}>
                                                        <div className={classes.id}>{t('selectChallenge.id')}</div>
                                                        <div data-testid="tpName">{challenge.tpName}</div>
                                                    </Typography>
                                                </Stack>

                                                <Stack
                                                    spacing={1}
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                >
                                                    <Typography variant="tXsMedium" className={classes.chip}>
                                                        <span data-testid={'challengeType'}>
                                                            {challenge.challengeType}
                                                        </span>
                                                    </Typography>
                                                    <div className={classes.vIconPlaceholder}>
                                                        {challenge.tpName === selectedChallenge?.tpName && (
                                                            <Icon name={IconsType.CheckSvg} />
                                                        )}
                                                    </div>
                                                </Stack>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </div>
                            </div>
                        </Drawer>
                    </Hidden>
                </>
            )}
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChallengeHeaderSelect));
