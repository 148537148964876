import { makeStyles } from '@material-ui/core';
import { isMobile } from 'helpers/device_helper';
import { newShadows } from 'muiTheme/config/palette';

export default makeStyles((theme) => ({
    dialogPaper: {
        width: '448px',
        maxWidth: '448px',
        borderRadius: '12px',
        boxShadow: newShadows.xl,
        padding: '24px',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grayVariants[25] : theme.palette.background.default,
        ...isMobile({ width: '100%', maxWidth: '100%', padding: ' 20px 16px' }),
    },
    backDrop: { backdropFilter: 'blur(8px)' },
    iconsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1.6rem !important',
    },
    closeButton: {
        color: theme.palette.grayVariants[500],
        width: '40px',
        height: '40px',
        padding: '10px',
    },
    title: {
        color: theme.palette.grayVariants[900],
        marginBottom: '4px !important',
    },
    description: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[600] : theme.palette.grayVariants[600],
        marginBottom: '16px !important',
    },

    button: {
        height: '48px',
        width: '100%',
        padding: '10px 16px',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '12px',
        ...isMobile({
            flexDirection: 'column-reverse',
        }),
    },
    backButton: {
        height: '48px',
        width: '100%',
        padding: '10px 16px',
        borderColor: theme.palette.grayVariants[300],
    },
    backBtnText: {
        textWrap: 'nowrap',
    },
    retryExpiration: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[700] : theme.palette.grayVariants[600],
        marginBottom: '32px !important',
        textAlign: 'center',
    },
    closeIcon: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[300] : theme.palette.grayVariants[500],
    },
    discount: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[600] : theme.palette.grayVariants[600],
        fontWeight: 700,
    },
    id: {
        color: theme.palette.type === 'dark' ? theme.palette.grayVariants[500] : theme.palette.grayVariants[700],
    },
}));
