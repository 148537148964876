/* tslint:disable */
/* eslint-disable */
/**
 * Swagger
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { RetryParams } from './retry-params';

/**
 *
 * @export
 * @interface PlanDetails
 */
export class PlanDetails {
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    planId?: string;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    account?: string;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    relatedTransaction?: string;
    /**
     *
     * @type {number}
     * @memberof PlanDetails
     */
    basicDepositAmount?: number;
    /**
     *
     * @type {number}
     * @memberof PlanDetails
     */
    addOnsSummaryAmount?: number;
    /**
     *
     * @type {number}
     * @memberof PlanDetails
     */
    totalOrderSummaryAmount?: number;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    orderDiscountCode?: string;
    /**
     *
     * @type {number}
     * @memberof PlanDetails
     */
    orderDiscountAmount?: number;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    orderDiscountType?: PlanDetailsOrderDiscountTypeEnum;
    /**
     *
     * @type {number}
     * @memberof PlanDetails
     */
    finalDepositAmount?: number;
    /**
     *
     * @type {number}
     * @memberof PlanDetails
     */
    discountValue?: number;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    type?: PlanDetailsTypeEnum;
    /**
     *
     * @type {RetryParams}
     * @memberof PlanDetails
     */
    retryOptions?: RetryParams;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    endTime?: string;
    /**
     *
     * @type {string}
     * @memberof PlanDetails
     */
    planStatus?: PlanDetailsPlanStatusEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PlanDetailsOrderDiscountTypeEnum {
    Fixed = 'Fixed',
    Percent = 'Percent',
}
/**
 * @export
 * @enum {string}
 */
export enum PlanDetailsTypeEnum {
    Paid = 'Paid',
    Free = 'Free',
}
/**
 * @export
 * @enum {string}
 */
export enum PlanDetailsPlanStatusEnum {
    NotStarted = 'NotStarted',
    Active = 'Active',
    HitEquityTarget = 'HitEquityTarget',
    Draft = 'Draft',
    ManuallyDisqualified = 'ManuallyDisqualified',
    HitTerminationEquityLevel = 'HitTerminationEquityLevel',
    HitMaxDailyLossPercent = 'HitMaxDailyLossPercent',
    HitMaxFloatingCashLoss = 'HitMaxFloatingCashLoss',
    HitMaxFloatingLossPercent = 'HitMaxFloatingLossPercent',
    HitMaxDrawdownPercent = 'HitMaxDrawdownPercent',
    ReachedEndTime = 'ReachedEndTime',
    SpecialCaseEndTime = 'SpecialCaseEndTime',
    LackOfTradingActivity = 'LackOfTradingActivity',
    OpenPendingOrderAtDayEnd = 'OpenPendingOrderAtDayEnd',
    OpenPendingOrderOverWeekend = 'OpenPendingOrderOverWeekend',
    EquityChangeBeforeStart = 'EquityChangeBeforeStart',
    OppositeDirectionTradeFlag128 = 'OppositeDirectionTradeFlag128',
    ChallengeTerminatedInCompetition = 'ChallengeTerminatedInCompetition',
    AccountReceivedAdditionalFunding = 'AccountReceivedAdditionalFunding',
    ChallengeDeleted = 'ChallengeDeleted',
}
